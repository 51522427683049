import { Component, OnInit } from '@angular/core';

declare var window:any
@Component({
  selector: 'Instant-UC-iframe-url',
  templateUrl: './iframe-url.component.html',
  styleUrls: ['./iframe-url.component.scss']
})
export class IframeUrlComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    function callParentFunction(str: any) {
      if (
        str &&
        str.length > 0 &&
        window.parent &&
        window.parent.parent &&
        window.parent.parent.AuthorizeNetIFrame &&
        window.parent.parent.AuthorizeNetIFrame.onReceiveCommunication
      ) {
        // Errors indicate a mismatch in domain between the page containing the iframe and this page.
        window.parent.parent.AuthorizeNetIFrame.onReceiveCommunication(str);
      }
    }
 
    function receiveMessage(event: any) {
      if (event && event.data) {
        callParentFunction(event.data);
      }
    }
 
    if (window.addEventListener) {
      window.addEventListener('message', receiveMessage, false);
    } else if (window.attachEvent) {
      window.attachEvent('onmessage', receiveMessage);
    }
 
    if (window.location.hash && window.location.hash.length > 1) {
      callParentFunction(window.location.hash.substring(1));
    }
  }
 

}
