import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, Router } from '@angular/router';
import { PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';

@Injectable()

export class loginAuthgardComponent implements CanActivate, CanActivateChild{

    constructor(public router:Router ,   @Inject(PLATFORM_ID) private platformId: any
    ) { } 


canActivate() {
    if (isPlatformBrowser(this.platformId)) {
        if (localStorage.getItem("access_token")) {
            return true;
        }
        else {
            return false;
        }
    }
    
    }
    
    canActivateChild() {
        if (isPlatformBrowser(this.platformId)) {
        if (!localStorage.getItem("access_token")) {
            return true;
        }
        else {
            return false;
        }


        }
    }
        
}








    










