


<div class="main-wrapper">
  <router-outlet (activate)="onActivate($event)" >
  </router-outlet> 

  <InstantUC-loader [show]="loader"></InstantUC-loader>
  <p-toast [showTransformOptions]="'translateX(100%)'" sticky="true" position="bottom-right" sticky="true"></p-toast>

 
</div>


  
  
  
 
  
