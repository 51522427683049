// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.


export const environment = {
  production: false,
 API_BASE_PATH: "https://api.pcrown.net/",  //dev url
  Checkin_BASE_PATH:"https://pcrown.net",
  API_BASE_PATH_Hq: "https://api.pcrown.net/hq/",  //dev url
  HA_API_BASE_PATH: 'http://3.23.121.56/Healthsist/', // this is the dev environemtn
  HA_API_KEY: 'EV-OCT-EVENT-BIZBRO-2020LLY',
  API_KEY:"EV-OCT-EVENT-BIZBRO-2020LLY",
  USER_NAME: "abc",
  socketUrl: 'https://socket.pcrown.net/',
  aucSocketUrl:'https://aucsocket.pcrown.net/',
  PRACTICE_ID: 113801,
  DEP_ID: 22,
  d: 'https://api.pcrown.net/d/',
  auc:'https://api.pcrown.net/auc/',
  SGRH: 'https://api.pcrown.net/SGRH/',
  IUC: 'https://api.pcrown.net/IUC/',
  SelfOnboarding_Key: 'EV-OCT-EVENT-BIZBRO-2020LLY',
  medhealth:'https://api.practicecrown.com/Medhealth/',
  medhealthSocketUrl:'https://socket.pcrown.dev',
  siteKey : '6LfJcqUeAAAAAIFNPthA1Qm7qkI0HhaeDknP9lyG',
  adminUrl:'https://admin.pcrown.net',
  blogBaseUrl:"https://www.pcrown.net",
  googleMapKey:'AIzaSyCpYF-ryM0RTrFdwU-NCdfN5lQgG1vaV_w',
  customDropdownApiUrl: 'https://socket.pcrown.net/searchGoogleLocation',
  iucCovidTesting:'https://instantuc.com/rapid-covid-19-testing/',
  iucPrimaryCare:'https://instantuc.com/primary-care/',
  iucUrgentCare:'https://instantuc.com/urgent-care/',
  aucCovidTesting:'https://www.accessurgentcare.io/services#testing-services',
  aucPrimaryCare:'https://www.accessurgentcare.io/services',
  aucUrgentCare:'https://www.accessurgentcare.io/services#urgent-care',
ImageBasePath:'https://admin.pcrown.net',
transactionKey: "3hqNH3m7WM698u5D",
copayIframeUrl: `https://pcrown.net/IframeUrl`,
paymentUrl: `https://apitest.authorize.net/xml/v1/request.api`,
merchantName: `Instant UrgentCare`,
formActionUrl: `https://test.authorize.net/payment/payment`,
LoginId:'6u8CMf8p',
resetPasswordLink:'https://pcrown.net/d/portal/reset-password'
};

// export const environment = {
//   production: true,
//   API_BASE_PATH:"https://api.practicecrown.com/",
//   API_BASE_PATH_Hq: "https://api.practicecrown.com/hq/", // please don`t change the hq api base path
//   Checkin_BASE_PATH:"https://practicecrown.com",
//   d:'https://api.practicecrown.com/d/',
//   auc:'https://api.practicecrown.com/auc/',
//   API_KEY:"EV-OCT-EVENT-BIZBRO-2020LLY",
//   SelfOnboarding_Key:'EV-OCT-EVENT-BIZBRO-2020LLY',
//   DEP_ID:22,
//   PRACTICE_ID:11381,
//   USER_NAME:"abc",
//   socketUrl:'https://socket.practicecrown.com/',
//   aucSocketUrl:"https://aucsocket.practicecrown.com/",
//   SGRH:'https://api.practicecrown.com/SGRH/',
//   IUC: 'https://api.practicecrown.com/IUC/',
//   TWILIO_ACCOUNT_SID:"AC01a570bd3744e537297c979110e8646f",
//   TWILIO_API_KEY_SECRET :"UzmCwayaWMstvC74zQVqwRnO1JbtJLHi",
//   TWILIO_API_KEY_SID :"SKe9570f0d60cb8d3bd60378fbba15e416",
//   HA_API_KEY: 'EV-OCT-EVENT-BIZBRO-2020LLY',
//   HA_API_BASE_PATH: 'http://3.23.121.56/Healthsist/',
//   medhealth:'https://api.practicecrown.com/Medhealth/',
//   medhealthSocketUrl:'https://socket.pcrown.dev',
//   siteKey : '6LeRwk0gAAAAAP3rBC5O1KfqMdepTBlBh3rK8Ai7',
//   adminUrl:'https://admin.practicecrown.com',
//   blogBaseUrl:'https://practicecrown.com',
//   googleMapKey:'AIzaSyCpYF-ryM0RTrFdwU-NCdfN5lQgG1vaV_w',
//   iucCovidTesting:'https://instantuc.com/rapid-covid-19-testing/',
//   iucPrimaryCare:'https://instantuc.com/primary-care/',
//   iucUrgentCare:'https://instantuc.com/urgent-care/',
//   aucCovidTesting:'https://www.accessurgentcare.io/services#testing-services',
//   aucPrimaryCare:'https://www.accessurgentcare.io/services',
//   aucUrgentCare:'https://www.accessurgentcare.io/services#urgent-care',
//   customDropdownApiUrl: 'https://socket.practicecrown.com/searchGoogleLocation',
//   ImageBasePath:'https://admin.practicecrown.com',
//   transactionKey: "3hqNH3m7WM698u5D",
//   copayIframeUrl: `https://practicecrown.com/IframeUrl`,
//   paymentUrl: `https://apitest.authorize.net/xml/v1/request.api`,
//   merchantName: `Instant UrgentCare`,
//   formActionUrl: `https://test.authorize.net/payment/payment`,
//   LoginId:'6u8CMf8p',
//   resetPasswordLink:'https://practicecrown.com/d/portal/reset-password'
// };


// export const environment = {
//     production: false,
//    API_BASE_PATH: "https://api.pcrown.dev/",  //dev url
//    Checkin_BASE_PATH:"https://pcrown.dev",
//     API_BASE_PATH_Hq: "https://api.pcrown.dev/hq/",  //dev url
//     HA_API_BASE_PATH: 'http://3.23.121.56/Healthsist/', // this is the dev environemtn
//     HA_API_KEY: 'EV-OCT-EVENT-BIZBRO-2020LLY',
//     API_KEY:"EV-OCT-EVENT-BIZBRO-2020LLY",
//     USER_NAME: "abc",
//     socketUrl: 'https://socket.pcrown.dev/',
//     aucSocketUrl:'https://aucsocket.pcrown.dev/',
//     PRACTICE_ID: 113801,
//     DEP_ID: 22,
//     d: 'https://api.pcrown.dev/d/',
//     auc:'https://api.pcrown.dev/auc/',
//     SGRH: 'https://api.pcrown.dev/SGRH/',
//     IUC: 'https://api.pcrown.dev/IUC/',
//     SelfOnboarding_Key: 'EV-OCT-EVENT-BIZBRO-2020LLY',
//     medhealth:'https://api.practicecrown.com/Medhealth/',
//     medhealthSocketUrl:'https://socket.pcrown.dev',
//     siteKey : '6LfJcqUeAAAAAIFNPthA1Qm7qkI0HhaeDknP9lyG',
//     adminUrl:'https://admin.pcrown.dev',
//     blogBaseUrl:"https://www.pcrown.dev",
//     googleMapKey:'AIzaSyCpYF-ryM0RTrFdwU-NCdfN5lQgG1vaV_w',
//     customDropdownApiUrl: 'https://socket.pcrown.dev/searchGoogleLocation',
//     iucCovidTesting:'https://instantuc.com/rapid-covid-19-testing/',
//     iucPrimaryCare:'https://instantuc.com/primary-care/',
//     iucUrgentCare:'https://instantuc.com/urgent-care/',
//     aucCovidTesting:'https://www.accessurgentcare.io/services#testing-services',
//     aucPrimaryCare:'https://www.accessurgentcare.io/services',
//     aucUrgentCare:'https://www.accessurgentcare.io/services#urgent-care',
//     // googleMapKey:'AIzaSyCpYF-ryM0RTrFdwU-NCdfN5lQgG1vaV'
//     ImageBasePath:'https://admin.pcrown.dev',
//     transactionKey: "3hqNH3m7WM698u5D",
//   copayIframeUrl: `https://practicecrown.com/IframeUrl`,
//   paymentUrl: `https://apitest.authorize.net/xml/v1/request.api`,
//   merchantName: `Instant UrgentCare`,
//   formActionUrl: `https://test.authorize.net/payment/payment`,
//   LoginId:'6u8CMf8p',
//   resetPasswordLink:'https://pcrown.dev/d/portal/reset-password'
//   };





