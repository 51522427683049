import { Injectable } from '@angular/core';
import {MessageService} from 'primeng/api';
@Injectable({
    providedIn:'root'
})

export class TosterService {

    constructor(
        private messageService:MessageService 
        ) {}

    error(message: any){
        this.messageService.add({severity:'error',  detail:message});
    } 

    info(message: any){
        this.messageService.add({severity:'info',  detail:message});    } 

    warning(message: any){
        this.messageService.add({severity:'warn',  detail:message});    }

    success(message: any){
        this.messageService.add({severity:'success',  detail:message});    }

}