

<div class="content contant-small" *ngIf="isSuccess && isloaded">
    <!-- Calendar Events -->
    <div class="card">
       <div class="card-header plo">
          <h5 class="card-title mb-0" v=""><strong>Email Verification</strong><span class="service-title"></span></h5>
 
       </div>
 
       <div class="card-body">
        <section class="contact-area section-padding-100">
            <form [formGroup]="passwordForm" (ngSubmit)="savePassword()">
                <div class="row no-gutters">
                    <div class="alert success-alert" role="alert">
                        <p class="success-message">Congratulation, email verification is completed</p>
                        <p class="success-message">Please set the password below</p>
                      </div>
                    <!-- Contact Form Area -->
                    <div class="col-12">
                        <div class="contact-form">
                            <div class="col-12">
                                <div class="form-group">
                                    <label>Password<span style="color: red;">*</span></label>
                                    <input formControlName="Password" type="password" class="textbox form-control" 
                                        [ngClass]="{'error-message':passwordForm.controls['Password'].hasError('required') && isValidForm}">
                                    <span class="highlight"></span>
                                    <span class="bar"></span>
                                    <p class="error-message-text"
                                        *ngIf="passwordForm.controls['Password'].hasError('required') && isValidForm">
                                        Password is Required.
                                    </p>
                                    <p class="error-message-text" 
                                        *ngIf="passwordForm.controls['Password'].hasError('minlength') && isValidForm">
                                        Password should be minimum 6 digits. </p>
                                </div>

                                <div class="form-group">
                                    <label>Confirm Password<span style="color: red;">*</span></label>
                                    <input formControlName="confirmPasseord" type="password" class="textbox form-control" 
                                        [ngClass]="{'error-message':passwordForm.controls['confirmPasseord'].hasError('required') && isValidForm}">
                                    <span class="highlight"></span>
                                    <span class="bar"></span>
                                    <p class="error-message-text"
                                        *ngIf="passwordForm.controls['confirmPasseord'].hasError('required') && isValidForm">
                                        Password is Required.
                                    </p>
                                    <p class="error-message-text"
                                    *ngIf="!isVerifyed">
                                        New password and confirm password don't match
                                    </p>
                                    
                                    
                                </div>
                                
                                <div class="text-center">
                                    <input type="submit" class="btn btn-primary  float-no" value="Submit">
                            
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>

            </form>

        </section>
 
       </div>
    </div>
 
 
 
 
 
 
 
 </div>

 <div class="content contant-small" *ngIf="!isSuccess && isloaded">
  <div class="card" *ngIf="!isSuccess && isloaded">
       <div class="card-body">
          <div class="alert alert-danger" role="alert">
             This link has expired. please send a new request
        </div>
    </div>
  </div>
 
 </div>