<div class="content  justify-content-center">
    <div class="practice-crow-preloader">
      
        <i  class="fa fa-spinner fa-spin" ></i>
    </div>
    <!-- Main content -->
    <div class="content-wrapper">
        <!-- Content area -->

        <!-- Dashboard content -->
        <div class="container">
            <div class="row">
                <div class="col-xl-12 ml-auto mr-auto">
                    <section class="contact-area section-padding-100">

                        <div class="row no-gutters">
                            <div class="col-12">
                                <!-- <div class="practice-crown-logo">
                                <img src="assets/img/practisecrownlogo.png" alt="logo" style="width:100%;"> -->
                                    <!-- <h1>Loading...</h1> 
                                    <i  class="fa fa-spinner fa-spin" ></i>
                                </div> -->
                            </div>
                            <!-- Contact Form Area -->
                            <!-- <div class="row"> -->
                                <!-- <div class="col-md-4" pid = "113801" did="22"  id="Department-generater"></div>
                                <div class="col-md-4" pid = "113801" did="5" id="Department-generater-type-2"></div> -->
                                <div class="col-12" style="display: block;">

                                    <!-- <div id="Department-generater-type-1" pid = "11381" did="0" style="padding-top: 40px;"></div> -->
                                    <!-- <div id="Department-generater-type-2" pid = "113801" did="0" noOfSlot="5" type="h" style="padding-top: 40px;"></div> -->
                                    <!-- <div id="Department-generater-type-3" pid = "113801" did="22" noOfSlot="5" type="h" providerId="2" style="padding-top: 40px;"></div> -->
                                    <!-- <div id="Department-generater-type-3-2" pid = "11381" did="22" noOfSlot="5" type="h" providerId="2" style="padding-top: 40px;"></div> -->
                                </div>
                            <!-- </div> -->
                        </div>

                    </section>

                </div>

            </div>
            
        </div>
    </div>
</div>