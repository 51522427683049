import { filter } from "rxjs/operators";
import { Component, Inject, OnInit, PLATFORM_ID } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";


import { ActivatedRoute } from "@angular/router";
import { GlobalService } from "@Globalservice";
import { environment } from "@env";
import { IAppointmentMTService } from "./services/interface/appointment/i.appointment-method";
import { isPlatformBrowser } from "@angular/common";
// import {SESSION_STORAGE, WebStorageService} from 'angular-webstorage-service';
// declare var window;
declare var $: any;
declare var gtag: any;


@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  title = "InstantUC";
  department_details;
  value = 50;
loader:boolean = true;
  constructor(
    public globalService: GlobalService,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: Object // private iAppointmentMTService: IAppointmentMTService,
  ) // private activatedRoute:ActivatedRoute
  {
    const navEndEvent = this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd)
    );

    navEndEvent.subscribe((event: NavigationEnd) => {
      if (isPlatformBrowser(this.platformId)) {
        gtag("config", "UA-177397371-1", {
          page_path: event.urlAfterRedirects,
        });
      }
    });
  }

  ngOnInit() {}

  onActivate(event) {
    
    if (isPlatformBrowser(this.platformId)) {
      $(document.body).animate({ scrollTop: 0 }, 600);

    }
    this.loader = false;
  }
  // SIGNATURE CODE

  // SIGNATURE CODE

  
}
