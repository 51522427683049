import { Component, OnInit,NgZone, Inject} from '@angular/core';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { DynamicScriptLoaderService} from '../services/data-service/DynamicScriptLoaderService';
declare var window;

@Component({
  selector: 'Instant-UC-department-visit',
  templateUrl: './department-visit.component.html',
  styleUrls: ['./department-visit.component.scss'],
  // providers:[DynamicScriptLoaderService]
})
export class DepartmentVisitComponent implements OnInit {
  constructor( private router:Router,private zone: NgZone , @Inject(DOCUMENT) private doc:any) {
    let link: HTMLLinkElement = doc.createElement('link');
    link.setAttribute('rel', 'canonical');
    doc.head.appendChild(link);
    link.setAttribute('href', doc.URL);  
    //  window.location.href="https://pcrown.net"; 
    
  }

  ngOnInit() { 

  }

  goToAppointmentPage(department){
    // this.router.navigateByUrl('/appointment')
    this.router.navigateByUrl(`/p/${department.PracticeId}/d/${department.DepartmentId}/appointment`)
  }

}
