import { Injectable, Injector } from '@angular/core';
import { HttpService } from '../http/http.service';
import { Observable } from 'rxjs';
import { InstantUcApiModels } from '../interface/Api/i.api-response';
import { IAccountService } from '../interface/account/i.account-method';
import {environment} from '@env'
@Injectable({ providedIn: 'root' })

export class AccountService implements IAccountService {

    apiController: string = "api/Account"
    httpSerive: HttpService;

    constructor(injector: Injector) {
        this.httpSerive = injector.get(HttpService);
    }


    SignUp(body): Observable<InstantUcApiModels.InstantUcApiResponse> {
        let url = `${this.apiController}/SignUp`;
        return this.httpSerive.post(url, body);
    }

    PatientSignUp(body): Observable<InstantUcApiModels.InstantUcApiResponse> {
        let url = `${this.apiController}/PatientSignUp`;
        return this.httpSerive.post(url, body);
    }

    GetUserInfoByActivationCode(body): Observable<InstantUcApiModels.InstantUcApiResponse>{
        let url = `${this.apiController}/GetUserInfoByActivationCode/`;
        return this.httpSerive.post(url, body);
    }

    SetPassword(body): Observable<InstantUcApiModels.InstantUcApiResponse>{
        let url = `${this.apiController}/SetPassword/`;
        return this.httpSerive.post(url, body);
    }

    PatientLogin(body): Observable<InstantUcApiModels.InstantUcApiResponse>{
        let url = `${this.apiController}/PatientLogin/`;
        return this.httpSerive.post(url, body);
    }

    UserLogin(body): Observable<InstantUcApiModels.InstantUcApiResponse>{
        let url = `${this.apiController}/UserLogin/`;
        return this.httpSerive.post(url, body);
    }

    LogOut(body):Observable<InstantUcApiModels.InstantUcApiResponse>{
        let url=`${this.apiController}/LogOut`;
        return this.httpSerive.post(url,body);
    }

    ForgotPassword(body):Observable<InstantUcApiModels.InstantUcApiResponse>{
        let url=`${this.apiController}/PatientForgotPassword`;
        return this.httpSerive.post(url,body);
    }

    ResetPassword(body):Observable<InstantUcApiModels.InstantUcApiResponse>{
        let url=`${this.apiController}/ResetPassword`;
        return this.httpSerive.post(url,body);
    }

    GetTillowToken(body):Observable<any>{
        let url=`/token?identity=${body.identity}&roomName=${body.roomName}`;
        return this.httpSerive.getTillowToken(url);
    }

    roomDistroy(body):Observable<any>{
        let url=`/updateTwilioRoomStatus`;
        return this.httpSerive.postForSocketApi(url,body);
    }

    ParticipantList(body){
        let url=`/getParticipantList?roomName=${body.roomName}`;
        return this.httpSerive.getTillowToken(url);
    }

    createRoom(body){
        let url=`/createRoom?identity=${body.identity}&roomName=${body.roomName}&Pname=${body.PName}`;
        return this.httpSerive.getTillowToken(url);
    }

    

    

    
   

     

}


















