import { Injectable } from "@angular/core";
import { CanActivate, CanActivateChild, Router } from "@angular/router";
import { PLATFORM_ID, Inject } from "@angular/core";
import { isPlatformBrowser, isPlatformServer } from "@angular/common";

@Injectable({
  providedIn:'root'
})
export class PatientPortalRouteGuard implements CanActivateChild {
  constructor(
    public router: Router,
    @Inject(PLATFORM_ID) private platformId: any
  ) {}

  canActivateChild() {
    if (isPlatformBrowser(this.platformId)) {
    let tokenAccess=sessionStorage.getItem("PortalLoginToken")
    if (!tokenAccess) {
       window.location.href='https://instantuc.com'
    } else {
      return true;

    }
  }
  }

} 
