import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder,Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {IAccountService} from '../services/interface/account/i.account-method'
import sha1 from 'sha1'; 

import {TosterService} from '@toster'

@Component({
  selector: 'Instant-UC-email-verification',
  templateUrl: './email-verification.component.html',
  styleUrls: ['./email-verification.component.scss']
})
export class EmailVerificationComponent implements OnInit {

  activationCode:string=null;
  passwordForm:FormGroup;
  isSuccess: boolean;
  isloaded: boolean;
  isValidForm: boolean;
  isVerifyed: boolean=true;
  userDetail: any;
  constructor(
    private activatedRoute:ActivatedRoute,
    private iAccountService:IAccountService,
    private tosterService:TosterService,
    private fb:FormBuilder,
    private router:Router,
    @Inject(DOCUMENT) private doc:any) { 
      let link: HTMLLinkElement = doc.createElement('link');
    link.setAttribute('rel', 'canonical');
    doc.head.appendChild(link);
    link.setAttribute('href', doc.URL); 

    this.activatedRoute.queryParams.subscribe(params => {
      this.activationCode = params.AC;
      this.verification(this.activationCode);
    })

    this.passwordForm = this.fb.group({
      Password:['',[Validators.required,Validators.minLength(6)]],
      confirmPasseord:['',[Validators.required]],
      ActivationCode:[this.activationCode]
    })
  }

  ngOnInit() {
    this.passwordForm.valueChanges.subscribe(res=>{
      this.isVerifyed = true;
    })
  }

  verification(AC){
    this.iAccountService.GetUserInfoByActivationCode({ActivationCode:AC}).subscribe(res=>{
      this.isloaded = true;
      if(res.Data){
        this.userDetail = res.Data;
        this.isSuccess = true;
      }else{
        this.isSuccess = false;
      }
    },error=>{
      this.isloaded = true;
      this.isSuccess = false;
      
    })
  }

  savePassword(){
    if(this.passwordForm.valid){
      if(this.passwordForm.controls['Password'].value === this.passwordForm.controls['confirmPasseord'].value){
      let data = this.passwordForm.value;
      data.Password = sha1(this.passwordForm.controls['Password'].value)
      data.confirmPasseord = sha1(this.passwordForm.controls['confirmPasseord'].value)
      this.iAccountService.SetPassword(data).subscribe(res=>{
    
        this.tosterService.success(res.Data);
        setTimeout(() => {
          this.router.navigateByUrl(`p/${this.userDetail.PracticeId}/d/${this.userDetail.DepartmentId}/appointment`);
        }, 3000);

      },error=>{
      
      })
    }else{
      this.isVerifyed = false;
    }
    }else{
      this.isValidForm = true;
    }
  }

}
