import 'hammerjs';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

// function bootstrap() {
//      platformBrowserDynamic().bootstrapModule(AppModule)
//   .catch(err => console.error(err));
//    };


// if (document.readyState === 'complete') {
//   bootstrap();
// } else {
//   document.addEventListener('DOMContentLoaded', bootstrap);
// }


function bootstrapApp() {
  import('./app/app.module').then(({ AppModule }) => {
    platformBrowserDynamic().bootstrapModule(AppModule).catch(err => console.error(err));
  });
}

if (typeof window !== 'undefined') {
  // Only run this in the browser
  if (document.readyState === 'complete') {
    bootstrapApp();
  } else {
    document.addEventListener('DOMContentLoaded', bootstrapApp);
  }
} else {
  // In SSR context, do nothing or perform server-specific initialization
}


