import { Component, OnInit, Input, ChangeDetectionStrategy, NgModule } from '@angular/core';
import {CommonModule} from '@angular/common'
@Component({
  selector: 'InstantUC-loader',
  template: `
  <div class="preloader" *ngIf="loader">
            <div class="loading">
                <div class="line"></div>
                <div class="line"></div>
                <div class="line"></div>
                <div class="line"></div>
                <div class="line"></div>
                <div class="line"></div>
                <div class="line"></div>
                <div class="line"></div>
            </div>
    </div>
  `,
  styleUrls:['./loader.css'],
})
export class loaderComponent implements OnInit {
  @Input('show') loader:boolean;
  constructor() {
    
   }

  ngOnInit(){
  }
}

@NgModule({
  declarations:[loaderComponent],
  imports:[CommonModule],
  exports:[loaderComponent]
})

export class InstantUCWebLoaderModule { }


